/** START CORE PROCESS-STORES */
!function (window) {
  /** CORE: (Re-)Process data store(s) */
  var namespace = window._ns_bbcws = window._ns_bbcws || "s_bbcws";

  var data = window[namespace+"_d"] || window[namespace]._data;
  var j = data.length;
  for (var i = 0 ; i < j ; i++) {
    var propertyName = data[i][0];

    if(
      (propertyName == 'track' && window[namespace]._hasTrackEvent(data[i][1])) ||
      (propertyName != 'track' && window[namespace]._getFunction(propertyName))
    ) {
      // Run a setter-function if available.
      // "Track" function only to execute if desired tracking event exists, else store for later use.
      window[namespace].apply(window[namespace], data[i]);
      data.splice(i--,1);
      j--;
    }
  }

  window[namespace + "_d"] = undefined;
}(window)
/** END CORE PROCESS-STORES */
