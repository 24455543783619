/** START CORE TRACKER  */
!function (window) {
  var namespace = window._ns_bbcws = window._ns_bbcws || "s_bbcws";

  // Determine if core tracker is loaded and, if not, load it.
  if (!(window[namespace] && window[namespace]._trackEvents)) {
    var sources = window[namespace] && window[namespace].sources ? window[namespace].sources : [];
    var baseUrl = window[namespace] && window[namespace]._baseUrl ? 
      window[namespace]._baseUrl : 
      'https://news.files.bbci.co.uk/ws/partner-analytics';
      
    window[namespace] = function () {
      var setterFn = window[namespace]._getFunction(arguments[0]);

      if (setterFn) {
        Array.prototype.shift.apply(arguments);
        return setterFn.apply(window[namespace], arguments);
      } else {
        window[namespace]._data.push(arguments);
      }
    }

    window[namespace]._version = LIBRARY_VERSION;
    window[namespace].sources = sources;
    window[namespace]._baseUrl = baseUrl;

    window[namespace]._getVersion = function () {
      var version = 'synd_v' + window[namespace]._version;
      if (undefined !== window[namespace]._data.find('syndSource')) {
        version += "_" + window[namespace]._data.find('syndSource');
      }

      return version;
    }

    window[namespace]._getFunction = function (methodName) {
      if (typeof this[methodName] == "function") {
        return this[methodName];
      }

      var setterName = 'set'+ methodName.charAt(0).toUpperCase() + methodName.slice(1);
      if (typeof this[setterName] == "function") {
        return this[setterName];
      }
    }

    window[namespace].track = function () {
      var type = Array.prototype.shift.call(arguments);

      if (this._trackEvents[type]) {
        for (var i = 0; i < this._trackEvents[type].length; i++) {
          this._trackEvents[type][i].apply(this, arguments);
          
          this._trackLog.push(type);
        }
      } else {
        throw "Specified tracking event not registered.";
      }
    }

    window[namespace]._trackEvents = {};
    window[namespace]._trackLog = [];

    window[namespace]._hasTrackEvent = function (eventName, callback) {
      var trackEvent = this._trackEvents[eventName] || {};
      var callbacks = trackEvent || [];

      if (typeof callback == "function") {
        for (var i = 0; i < callbacks.length; i++) {
          if (callbacks[i].name == callback.name) {
            return true;
          }
        }
        return false;
      }

      return callbacks.length > 0;
    }

    window[namespace]._registerTrackEvent = function (eventName, callback) {
      this._trackEvents[eventName] = this._trackEvents[eventName] || [];

      if (!this._hasTrackEvent(eventName, callback)) {
        this._trackEvents[eventName].push(callback);
      } else {
        throw "Specified tracking callback already registered.";
      }
    }
    
    window[namespace]._enums = {
      orgUnits: {
        ws: 'ws',
        gnl: 'gnl'
      },
      ati: {
        destinations: {
          wsSyndication: "598346",
          gnlSyndication: "598314",
          socialSyndication: "601719"
        },
        producers: {
          // WS Producers:
          afaanoromoo: { id: 2, name: "Afaan Oromoo" },
          afrique: { id: 3 },
          amharic: { id: 4 },
          arabic: { id: 5 },
          azeri: { id: 6 },
          bengali: { id: 31 },
          brasil: { id: 33 }, // a.k.a. portuguese
          burmese: { id: 35 },
          chinese: { id: 38 },
          gahuza: { id: 40 }, // a.k.a. greatlakes
          gujarati: { id: 50 },
          hausa: { id: 51 },
          hindi: { id: 52 },
          igbo: { id: 53 },
          indonesian: { id: 54 },
          korean: { id: 57 },
          kyrgyz: { id: 58 },
          marathi: { id: 59 },
          mundo: { id: 62 },
          nepali: { id: 63 },
          pashto: { id: 68 },
          persian: { id: 69 },
          pidgin: { id: 70 },
          punjabi: { id: 73 },
          russian: { id: 75 },
          serbian: { id: 81 },
          sinhala: { id: 82 },
          somali: { id: 83 },
          swahili: { id: 86 },
          tamil: { id: 87 },
          telugu: { id: 89 },
          thai: { id: 90 },
          tigrinya: { id: 91 },
          turkish: { id: 92 },
          ukchina: { id: 93, name: "UK China" },
          ukrainian: { id: 94 },
          urdu: { id: 95 },
          uzbek: { id: 96 },
          vietnamese: { id: 97 },
          yoruba: { id: 107 },
          wsenglish: { id: 103, name: "World Service English" },
          learningenglish: { id: 105, name: "WS Learning English" },
          // GNL Specific Producers:
          japanese: { id: 56 },
          // News Producers:
          news: { id: 64, name: "News" },
          englishregions: { id: 39, name: "English Regions" },
          newsenglishregions: { id: 108, name: "English Regions – News" },
          northernireland: { id: 66, name: "Northern Ireland" },
          newsnorthernireland: { id: 114, name: "Northern Ireland – News" },
          scotland: { id: 79 },
          newsscotland: { id: 110, name: "Scotland – News" },
          wales: { id: 100 },
          newswales: { id: 112, name: "Wales – News" },
          // Other:
          other: { id: 67 }
        },
        producerAliases: {
          'brazil': 'brasil',
          'portuguese': 'brasil',
          'portugese': 'brasil,',
          'greatlakes': 'gahuza',
          'turkce': 'turkish',
          'ukranian': 'ukrainian'
        }
      }
    };
    
    var pageCounterProducerMappings = [
      {
        pattern: /^news\.(england|world\.europe\.(isle_of_man|guernsey|jersey))\./,
        producer: 'newsenglishregions'
      },
      {
        pattern: /^news\.northern_ireland\./,
        producer: 'newsnorthernireland'
      },
      {
        pattern: /^news\.scotland\./,
        producer: 'newsscotland'
      },
      {
        pattern: /^news\.wales\./,
        producer: 'newswales'
      },
      {
        pattern:/^news\./,
        producer: 'news'
      },
      {
        pattern: /^naidheachdan\./,
        producer: 'scotland'
      },
      {
        pattern: /^newyddion\./,
        producer: 'wales'
      }
    ];
    
    window[namespace]._getPageCounter = function () {
      var story = this._data.find('story');
      if (story && story.getPageCounter()) {
        return story.getPageCounter();
      }
      
      return document.location.pathname.substr(1, document.location.pathname.length-1).replace(/\//g,'.') + '.page'
    }
    
    window[namespace]._producerExists = function(producer) {
      producer = sanitizeProducerName(producer);
      return Object.keys(this._enums.ati.producers).indexOf(producer) >= 0 || 
             Object.keys(this._enums.ati.producerAliases).indexOf(producer) >= 0;
    }
    
    function sanitizeProducerName (producer) {
      return producer.toLowerCase().trim();
    }
    
    function getProducerAlias (producer) {
      producer = sanitizeProducerName(producer);
      return window[namespace]._enums.ati.producerAliases[producer];
    }
    
    window[namespace].setProducer = function (producer) {
      if (this._producerExists(producer)) {
        this._data.push(['producer', getProducerAlias(producer) || sanitizeProducerName(producer)]);
      }
    }
    
    window[namespace]._getProducer = function () {
      if (this._data.find('story') && this._data.find('story').pageCounter) {
        for (var i=0 ; i < pageCounterProducerMappings.length ; i++) {
          if (pageCounterProducerMappings[i].pattern.test(this._data.find('story').pageCounter)) {
            return pageCounterProducerMappings[i].producer;
          }
        }
      }
      
      return this._data.find('producer');
    }
    
    window[namespace].setLanguage = function (language) {
      if (this._producerExists(language)) {
        this.setProducer(language);
      } else {
        this._data.push(['language', language]);
      }
      
      this._data.push(['languageRaw', language]);
    }
    
    window[namespace]._sendAtiBeacon = function (params) {
      var now = new Date(), 
          url = 'https://a1.api.bbc.co.uk/hit.xiti?', 
          params = params || [];
      
      // Set L1 site ID:
      if (this._data.find('platform') == 'fia') {
        params.push('s=' + this._enums.ati.destinations.socialSyndication);
      } else if (this._data.find('orgUnit') == 'gnl' || this._data.find('producer') == 'japanese') {
        params.push('s=' + this._enums.ati.destinations.gnlSyndication);
      } else {
        params.push('s=' + this._enums.ati.destinations.wsSyndication);
      }
      
      // Set L2 site ID:
      if (this._getProducer() && this._producerExists(this._getProducer())) {
        params.push('s2=' + this._enums.ati.producers[this._getProducer()].id);
      }
      
      params.push('lng='+window.navigator.language||window.navigator.userLanguage);
      params.push('hl='+now.getHours()+"x"+now.getMinutes()+"x"+now.getSeconds());
      params.push('ts='+now.getTime());  
      params.push('p='+this._getPageCounter());
      
      try {
        params.push('r='+window.screen.width+"x"+window.screen.height+"x"+window.screen.pixelDepth+"x"+window.screen.colorDepth);
      } catch (e) {}
      
      try {
        var re = '';
        if (window.innerWidth) {
          re = window.innerWidth+"x"+window.innerHeight;
        } else if (document.body&&document.body.offsetWidth) {
          re = document.body.offsetWidth+"x"+document.body.offsetHeight;
        }
        
        params.push('re='+re);
      
      } catch (e) {}
      
      // ref must be last parameter.
      params.push('ref='+document.referrer);
      
      url += params.join('&');
      
      if (document.images) {
        window[namespace]._atiBeacon = window[namespace]._atiBeacon || new Image();
        window[namespace]._atiBeacon.src = url;
      } else {
        document.write("<", "p", "><", 'img src="', e, '" height="1" width="1" alt="*"', "><", "/p", ">");
      }
    }
  }
  
  if (!(window[namespace]._data)) {
    window[namespace]._data = window[namespace+"_d"] || [];
    
    // To find simple value from key-value pair in store.
    window[namespace]._data.find = function (key) {
      var results = [];
      for (var i = 0; i < this.length; i++) {
        if (this[i][0] == key) {
          results.push(this[i]);
        }
      }

      if (results.length > 0) {
        return results[results.length - 1][1];
      }
    }
  }
}(window);
/** END CORE TRACKER  */
