const pageMeta = require('../lib/page');

/** START PAGEVIEW TRACKER */
!function(window) {
  var namespace = window._ns_bbcws = window._ns_bbcws || "s_bbcws";
  
  window[namespace].setStory = function (story) {
    switch (story.origin) {
      default:
      case 'cps':
        this._data.push(['story',new cpsStory(story)]);
        break;
    }
  }
  
  function cpsStory (story) {
    this.origin = 'cps';
    this.guid = story.guid;
    this.assetType = story.assetType;
    this.published = story.published;
    this.updated = story.updated;
    this.category = story.category;
    this.ldpThings = story.ldpThings || [];
    this.campaigns = story.campaigns || [];
    this.pageCounter = story.pageCounter;
  }
  
  cpsStory.prototype.getAtiContentId = function () {
    if (this.guid) {
      return 'urn:bbc:cps:' + this.guid;  
    }
  };
  
  cpsStory.prototype.getAtiContentType = function () {
    switch (this.assetType) {
      case 'STY': return 'article';
      case 'MAP': return 'article-media-asset';
    }
  }
  
  cpsStory.prototype.getAtiPublishedDate = function () {
    if (this.published) {
      var published = new Date(this.published);
      return published.toISOString().replace(/\.[0-9]{3}/,'')  
    }
  }
  
  cpsStory.prototype.getAtiUpdatedDate = function () {
    if (this.updated) {
      var updated = new Date(this.updated);
      return updated.toISOString().replace(/\.[0-9]{3}/,'')  
    }
  }
  
  cpsStory.prototype.getAtiCategory = function () {
    if (this.category) {
      return this.category;
    }
  }
  
  cpsStory.prototype.getAtiLdpThings = function () {
    if (this.ldpThings && this.ldpThings.length > 0) {
      var ldpThings = [];
      
      for (var n in this.ldpThings) {
        ldpThings.push(this.ldpThings[n].name);
      }
      
      return ldpThings.join('~');
    }
  }
  
  cpsStory.prototype.getAtiLdpThingsIds = function () {
    if (this.ldpThings && this.ldpThings.length > 0) {
      var ldpThingsIds = [];
      
      for (var n in this.ldpThings) { 
        ldpThingsIds.push(this.ldpThings[n].id);
      }
      
      return ldpThingsIds.join('~');
    }
  }
  
  cpsStory.prototype.getAtiCampaigns = function () {
    if (this.campaigns && this.campaigns.length > 0) {
      var campaigns = [];
      
      for (var n in this.campaigns) { 
        campaigns.push(this.campaigns[n].name);
      }
      
      return campaigns.join('~');
    }
  }
  
  cpsStory.prototype.getPageCounter = function () {
    if (this.pageCounter) {
      return this.pageCounter;
    }
  }
  
  function getPlatform () {
    if (window[namespace]._data.find('platform') == 'fia' || window.ia_document) {
      return 'fia';
    } else if (window[namespace]._data.find('platform')) {
      return window[namespace]._data.find('platform');
    }
  }
  
  /** BEGIN - ATI tracking code **/
  function atiPageView () {
    var params = [], 
        encoder = window.encodeURIComponent;
    
    params.push('x5=[' + encoder(pageMeta.getUrl()) + ']');
    params.push('x8=[' + encoder(this._getVersion()) + ']');
    params.push('x9=[' + encoder(pageMeta.getTitle()) + ']');
    
    if (pageMeta.getReferrer()) {
      params.push('x6=[' + encoder(pageMeta.getReferrer()) + ']');
    }
    
    if (this._data.find('language')) {
        params.push('x4=[' + encoder(this._data.find('language')) + ']');
    }
    
    if (this._data.find('partner')) {
        params.push('x19=[' + encoder(this._data.find('partner')) + ']');
    }
    
    if (this._data.find('partnerCompany')) {
        params.push('x20=[' + encoder(this._data.find('partnerCompany')) + ']');
    }
    
    if (getPlatform()) {
      params.push('x2=[' + getPlatform() + ']');
    } 
    
    var story = this._data.find('story');
    if (story) {
      if (story.getAtiContentId()) {
          params.push('x1=[' + encoder(story.getAtiContentId()) + ']');
      }
      
      if (story.getAtiContentType()) {
          params.push('x7=[' + encoder(story.getAtiContentType()) + ']');
      }
      
      if (story.getAtiPublishedDate()) {
        params.push('x11=[' + encoder(story.getAtiPublishedDate()) + ']');
      }
      
      if (story.getAtiUpdatedDate()) {
        params.push('x12=[' + encoder(story.getAtiUpdatedDate()) + ']');
      }
      
      if (story.getAtiCategory()) {
        params.push('x17=[' + encoder(story.getAtiCategory()) + ']');
      }
      
      if (story.getAtiLdpThings()) {
        params.push('x13=[' + encoder(story.getAtiLdpThings()) + ']');
      }
      
      if (story.getAtiLdpThingsIds()) {
        params.push('x14=[' + encoder(story.getAtiLdpThingsIds()) + ']');
      }
      
      if (story.getAtiCampaigns()) {
        params.push('x16=[' + encoder(story.getAtiCampaigns()) + ']');
      }
    }
    
    this._sendAtiBeacon(params);
  }
  
  window[namespace]._registerTrackEvent('pageView', atiPageView);
  /** END - ATI tracking code **/
  
}(window)
/** END PAGEVIEW TRACKER */
