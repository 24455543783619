module.exports = {
  getUrl: () => document.URL,
  
  getReferrer: () => {
    if (window.ia_document) {
      return window.ia_document.referrer;
    }
    
    return document.referrer
  },
  
  getTitle: () => {
    if (window.ia_document) {
      return window.ia_document.title;
    }
    
    return document.title;
  }
};
